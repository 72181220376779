import { BlockContentProps } from "@sanity/block-content-to-react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import ImageWithCaption from "../components/blocks/ImageWithCaption";
import DownloadButtons from "../components/DownloadButtons";
import ImageAndText from "../components/sections/ImageAndText";
import SignupForm from "../components/SignupForm";
import { useGlobalState } from "../state/globalState";

export interface SecurityProps {
  data: {
    site: {
      siteMetadata: {
        downloadActive: boolean;
      };
    };
    security1: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    security2: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    security3: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    securityIconShield: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    securityIconSlider: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    securityIconShredder: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
}

const Security: React.FC<SecurityProps> = ({
  data: {
    site: {
      siteMetadata: { downloadActive },
    },
    security1,
    security2,
    security3,
    securityIconShield,
    securityIconSlider,
    securityIconShredder,
  },
}) => {
  const { t } = useTranslation(["sign_up_form", "common", "security"]);

  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useGlobalState(
    "isDownloadPopupOpen"
  );

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "light",
      hidden: false,
    });
  }, []);

  const serializers = React.useMemo<BlockContentProps["serializers"]>(
    () => ({
      types: {
        imageWithCaption: ({ node }) => {
          return <ImageWithCaption {...node} />;
        },
      },
    }),
    []
  );

  return (
    <div>
      <section className="flex flex-col justify-center pt-32 mx-auto container-4xl">
        <div className="z-10">
          <h1 className="text-5xl md:text-6xl lg:text-6.5xl leading-none text-left text-gray-500 font-black uppercase">
            <Trans i18nKey="security:title">
              Nutze dein{" "}
              <span className="inline-block max-w-full font-black text-purple-500 break-words with-hyphens">
                Bankkonto
              </span>
              für eine
              <span className="inline max-w-full font-black break-words text-amber-500 with-hyphens">
                grüne Zukunft.
              </span>
            </Trans>
          </h1>
        </div>
      </section>
      <div className="px-4 pb-16 mt-6 container-4xl">
        <div className="prose-lg text-gray-500">{t("security:copytext")}</div>
      </div>

      <div className="flex flex-col py-12 space-y-12 container-5xl">
        <ImageAndText
          id="server_location"
          img={security1}
          headline={t("security:section_1.title")}
          body={
            <>
              <p>{t("security:section_1.paragraph_1")}</p>
            </>
          }
        />
        <ImageAndText
          id="technology_partners"
          img={security2}
          reversed
          headline={t("security:section_2.title")}
          body={
            <>
              <p>{t("security:section_2.paragraph_1")}</p>
            </>
          }
        />
        <ImageAndText
          id="data_security"
          img={security3}
          headline={t("security:section_3.title")}
          body={
            <>
              <p>{t("security:section_3.paragraph_1")}</p>
              <p>{t("security:section_3.paragraph_2")}</p>
            </>
          }
        />
      </div>

      <hr className="my-12" />

      <div className="flex flex-col pb-12 space-y-4">
        {downloadActive ? (
          <>
            <h2 className="text-center font-headline">
              {t("common:download.call_to_action")}
            </h2>
            <div className="flex flex-row justify-center space-x-4">
              <DownloadButtons
                buttonClassName="h-10"
                onClick={() => {
                  setIsDownloadPopupOpen(true);
                }}
              />
            </div>
          </>
        ) : (
          <>
            <h2 className="text-center font-headline">
              {t("sign_up_form:title")}
            </h2>
            <div className="flex flex-row justify-center">
              <SignupForm />
            </div>
            <span className="px-4 text-xs tracking-wide text-center sm:px-0 font-headline">
              {t("common:privacy.description")}
              &nbsp;
              <Link to="/datenschutzerklaerung" className="text-indigo-400">
                {t("common:privacy.privacy_policy")}
              </Link>
            </span>
          </>
        )}
      </div>

      <div className="py-12 space-y-12 bg-gray-100">
        <h3 className="text-xl text-center font-headline">
          {t("security:principles.title")}
        </h3>
        <div className="grid grid-cols-6 gap-8 container-5xl">
          <div className="flex flex-col items-center col-span-6 space-y-4 sm:col-span-2">
            <GatsbyImage
              image={securityIconShield.childImageSharp.gatsbyImageData}
              alt="shield"
            />
            <h3 className="text-lg text-center with-hyphens">
              {t("security:principles.principle_1.title")}
            </h3>
            <p className="text-sm text-center text-gray-500">
              {t("security:principles.principle_1.copytext")}
            </p>
          </div>
          <div className="flex flex-col items-center col-span-6 space-y-4 sm:col-span-2">
            <GatsbyImage
              image={securityIconSlider.childImageSharp.gatsbyImageData}
              alt="settings"
            />
            <h3 className="text-lg text-center with-hyphens">
              {t("security:principles.principle_2.title")}
            </h3>
            <p className="text-sm text-center text-gray-500">
              {t("security:principles.principle_2.copytext")}
            </p>
          </div>
          <div className="flex flex-col items-center col-span-6 space-y-4 sm:col-span-2">
            <GatsbyImage
              image={securityIconShredder.childImageSharp.gatsbyImageData}
              alt="shredder"
            />
            <h3 className="text-lg text-center with-hyphens">
              {t("security:principles.principle_3.title")}
            </h3>
            <p className="text-sm text-center text-gray-500">
              {t("security:principles.principle_3.copytext")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;

export const query = graphql`
  query SecurityQuery($language: String!) {
    site {
      siteMetadata {
        downloadActive
      }
    }
    security1: file(
      relativePath: { eq: "images/security/security-visual-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 120 }
        )
      }
    }
    security2: file(
      relativePath: { eq: "images/security/security-visual-2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 120 }
        )
      }
    }
    security3: file(
      relativePath: { eq: "images/security/security-visual-3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 120 }
        )
      }
    }
    securityIconShield: file(
      relativePath: { eq: "images/security/visual-shield.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 96
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 48 }
        )
      }
    }
    securityIconSlider: file(
      relativePath: { eq: "images/security/visual-slider.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 96
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 48 }
        )
      }
    }
    securityIconShredder: file(
      relativePath: { eq: "images/security/visual-shredder.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 96
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 48 }
        )
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
