import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

export interface ImageAndTextProps {
  id?: string;
  reversed?: boolean;
  img: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  lottieFile?: string;
  overline?: string;
  headline: string;
  body: React.ReactNode;
}

const ImageAndText: React.FC<ImageAndTextProps> = ({
  id,
  reversed,
  img,
  lottieFile,
  overline,
  headline,
  body,
}) => {
  return (
    <div className="grid grid-cols-2 gap-6" id={id}>
      <div
        className={`flex items-center col-span-2 md:col-span-1 ${
          reversed ? "md:order-2" : "md:order-1"
        }`}
      >
        {lottieFile ? null : (
          <GatsbyImage
            className="max-w-lg m-auto"
            image={img.childImageSharp.gatsbyImageData}
            alt={`screenshot of ${overline}`}
            // loading="eager"
          />
        )}
      </div>
      <div
        className={`m-auto text-center md:text-left col-span-2 max-w-md md:col-span-1 flex flex-col justify-center w-full ${
          reversed ? "md:order-1" : "md:order-2"
        }`}
      >
        {overline && (
          <span className="mb-2 text-xs font-bold tracking-widest text-gray-500 uppercase">
            {overline}
          </span>
        )}
        <h2 className="text-xl text-primary font-headline">{headline}</h2>
        <div className="flex flex-col mt-4 space-y-4 font-normal leading-relaxed tracking-wide text-gray-500">
          {body}
        </div>
      </div>
    </div>
  );
};

export default ImageAndText;
